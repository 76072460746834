const routesMap = {
  createIssuer: {
    authorizationLevels: ['megaadmin'],
    authorizations: [],
  },
  issuerList: {
    authorizationLevels: ['basic', 'brokerdealer', 'admin', 'superadmin', 'megaadmin'],
    authorizations: [],
  },
  dashboard: {
    authorizationLevels: ['basic', 'brokerdealer', 'admin', 'superadmin', 'megaadmin'],
    authorizations: [],
  },
  onboarding: {
    authorizationLevels: ['basic', 'brokerdealer', 'admin', 'superadmin', 'megaadmin'],
    authorizations: [],
  },
  fundraise: {
    authorizationLevels: ['basic', 'brokerdealer', 'admin', 'superadmin', 'megaadmin'],
    authorizations: [],
  },
  holders: {
    authorizationLevels: ['basic', 'brokerdealer', 'admin', 'superadmin', 'megaadmin'],
    authorizations: [],
  },
  issueDetails: {
    authorizationLevels: ['basic', 'brokerdealer', 'admin', 'superadmin', 'megaadmin'],
    authorizations: [],
    hidePerIssuerPermissions: true,
  },
  details: {
    authorizationLevels: ['basic', 'brokerdealer', 'admin', 'superadmin', 'megaadmin'],
    authorizations: [],
  },
  legalSigner: {
    authorizationLevels: ['basic', 'brokerdealer', 'admin', 'superadmin', 'megaadmin'],
    authorizations: [],
  },
  omnibusWalletOwnerDetails: {
    authorizationLevels: ['basic', 'brokerdealer', 'admin', 'superadmin', 'megaadmin'],
    authorizations: [],
  },
  signatures: {
    authorizationLevels: ['basic', 'admin', 'superadmin', 'megaadmin', 'brokerdealer'],
    authorizations: [],
    brokerDealerGroupIssuerPermissions: ['canAccessToIssuerSignatures'],
  },
  outreach: {
    authorizationLevels: ['basic', 'brokerdealer', 'admin', 'superadmin', 'megaadmin'],
    authorizations: [],
  },
  distributions: {
    authorizationLevels: ['superadmin', 'megaadmin'],
    authorizations: ['transfer-agent'],
    hidePerIssuerPermissions: true,
  },
  distributionDetails: {
    authorizationLevels: ['basic', 'admin', 'superadmin', 'megaadmin'],
    authorizations: ['transfer-agent'],
  },
  snapshots: {
    authorizationLevels: ['basic', 'admin', 'superadmin', 'megaadmin'],
    authorizations: [],
    hidePerIssuerPermissions: true,
  },
  snapshotDetails: {
    authorizationLevels: ['basic', 'admin', 'superadmin', 'megaadmin'],
    authorizations: [],
  },
  configuration: {
    authorizationLevels: ['basic', 'admin', 'superadmin', 'megaadmin', 'brokerdealer'],
    authorizations: [],
    brokerDealerGroupIssuerPermissions: ['canAccessToIssuerConfiguration'],
  },
  generalConfiguration: {
    authorizationLevels: ['basic', 'admin', 'superadmin', 'megaadmin', 'brokerdealer'],
    authorizations: [],
    brokerDealerGroupIssuerPermissions: ['canAccessToIssuerConfiguration'],
  },
  tokenConfiguration: {
    authorizationLevels: ['basic', 'admin', 'superadmin', 'megaadmin', 'brokerdealer'],
    authorizations: [],
    brokerDealerGroupIssuerPermissions: ['canAccessToIssuerConfiguration'],
  },
  jurisdictionsPerTokenConfiguration: {
    authorizationLevels: ['basic', 'admin', 'superadmin', 'megaadmin', 'brokerdealer'],
    authorizations: [],
    brokerDealerGroupIssuerPermissions: ['canAccessToIssuerConfiguration'],
  },
  fundraiseConfiguration: {
    authorizationLevels: ['basic', 'admin', 'superadmin', 'megaadmin', 'brokerdealer'],
    authorizations: [],
    brokerDealerGroupIssuerPermissions: ['canAccessToIssuerConfiguration'],
  },
  jurisdictionsConfiguration: {
    authorizationLevels: ['basic', 'admin', 'superadmin', 'megaadmin', 'brokerdealer'],
    authorizations: [],
    brokerDealerGroupIssuerPermissions: ['canAccessToIssuerConfiguration'],
  },
  advanced: {
    authorizationLevels: ['admin', 'superadmin', 'megaadmin'],
    authorizations: [],
  },
  issuerAffiliates: {
    authorizationLevels: ['admin', 'superadmin', 'megaadmin'],
    authorizations: [],
  },
  issuerAffiliateCampaigns: {
    authorizationLevels: ['admin', 'superadmin', 'megaadmin'],
    authorizations: [],
  },
  issuerAgreements: {
    authorizationLevels: ['admin', 'superadmin', 'megaadmin'],
    authorizations: [],
  },
  issuerBank: {
    authorizationLevels: ['admin', 'superadmin', 'megaadmin'],
    authorizations: [],
  },
  issuerCurrencies: {
    authorizationLevels: ['admin', 'superadmin', 'megaadmin'],
    authorizations: [],
  },
  issuerEmailTemplates: {

    authorizationLevels: ['admin', 'superadmin', 'megaadmin'],
    authorizations: [],
  },
  issuerPlatformDocuments: {
    authorizationLevels: ['admin', 'superadmin', 'megaadmin'],
    authorizations: [],
  },
  issuerQuestions: {
    authorizationLevels: ['admin', 'superadmin', 'megaadmin'],
    authorizations: [],
  },
  issuerTexts: {
    authorizationLevels: ['admin', 'superadmin', 'megaadmin'],
    authorizations: [],
  },
  issuerPrerenderedAddresses: {
    authorizationLevels: ['admin', 'superadmin', 'megaadmin'],
    authorizations: [],
  },
  issuerConfiguration: {
    authorizationLevels: ['megaadmin'],
  },
  'issuer-configuration': {
    authorizationLevels: ['megaadmin', 'brokerdealer', 'superadmin', 'admin', 'basic'],
    authorizations: [],
    brokerDealerGroupIssuerPermissions: ['canAccessToIssuerConfiguration'],
  },
  privacyControl: {
    authorizationLevels: ['megaadmin', 'brokerdealer', 'superadmin', 'admin', 'basic'],
    authorizations: ['privacy-controller'],
  },
  auditLogIssuer: {
    authorizationLevels: ['admin', 'superadmin', 'megaadmin'],
    authorizations: ['audit-log-issuer'],
  },
  transferControlBook: {
    authorizationLevels: ['brokerdealer', 'admin', 'superadmin', 'megaadmin'],
    authorizations: ['transfer-agent'],
  },
  fundraiseContent: {
    authorizationLevels: ['brokerdealer', 'admin', 'superadmin', 'megaadmin'],
    authorizations: [],
  },
  'transfer-agent': {
    authorizationLevels: ['brokerdealer', 'admin', 'superadmin', 'megaadmin'],
    authorizations: ['transfer-agent'],
  },
  transferMSF: {
    authorizationLevels: ['brokerdealer', 'admin', 'superadmin', 'megaadmin'],
    authorizations: ['transfer-agent'],
  },
  transferDTL: {
    authorizationLevels: ['brokerdealer', 'admin', 'superadmin', 'megaadmin'],
    authorizations: ['transfer-agent'],
  },
  transferProcedure: {
    authorizationLevels: ['brokerdealer', 'admin', 'superadmin', 'megaadmin'],
    authorizations: ['transfer-agent'],
  },
  affiliateManagement: {
    authorizationLevels: ['brokerdealer', 'admin', 'superadmin', 'megaadmin'],
    authorizations: ['transfer-agent'],
  },
  'securitize-id': {
    authorizationLevels: ['basic', 'admin', 'superadmin', 'megaadmin'],
    authorizations: ['securitize-id'],
  },
  securitizeId: {
    authorizationLevels: ['basic', 'admin', 'superadmin', 'megaadmin'],
    authorizations: ['securitize-id'],
  },
  securitizeIdInvestor: {
    authorizationLevels: ['basic', 'admin', 'superadmin', 'megaadmin'],
    authorizations: ['securitize-id'],
  },
  securitizeIdLegalSignerId: {
    authorizationLevels: ['basic', 'admin', 'superadmin', 'megaadmin'],
    authorizations: ['securitize-id'],
  },
  securitizeIdTranslations: {
    authorizationLevels: ['basic', 'admin', 'superadmin', 'megaadmin'],
    authorizations: ['securitize-id'],
  },
  securitizeIdWallets: {
    authorizationLevels: ['basic', 'admin', 'superadmin', 'megaadmin'],
    authorizations: ['securitize-id'],
  },
  operators: {
    authorizationLevels: ['brokerdealer', 'admin', 'superadmin', 'megaadmin'],
    authorizations: [],
  },
  systemCountries: {
    authorizationLevels: ['megaadmin'],
    authorizations: [],
  },
  systemAgreements: {
    authorizationLevels: ['megaadmin'],
    authorizations: [],
  },
  systemQuestions: {
    authorizationLevels: ['megaadmin'],
    authorizations: [],
  },
  systemEmailTemplates: {
    authorizationLevels: ['megaadmin'],
    authorizations: [],
  },
  systemTexts: {
    authorizationLevels: ['megaadmin'],
    authorizations: [],
  },
  clientConfiguration: {
    authorizationLevels: ['megaadmin'],
    authorizations: [],
  },
  'system-config': {
    authorizationLevels: ['megaadmin'],
    authorizations: [],
  },
  manageToken: {
    authorizationLevels: ['basic', 'admin', 'superadmin', 'megaadmin'],
    authorizations: ['on-chain-manager'],
  },
  'broker-dealer': {
    authorizationLevels: ['basic', 'admin', 'superadmin', 'megaadmin'],
    authorizations: ['securitize-markets', 'accreditation-lawyer'],
  },
  brokerDealerOverview: {
    authorizationLevels: ['basic', 'admin', 'superadmin', 'megaadmin'],
    authorizations: ['securitize-markets'],
  },
  brokerDealerInvestors: {
    authorizationLevels: ['basic', 'admin', 'superadmin', 'megaadmin'],
    authorizations: ['securitize-markets'],
  },
  brokerDealerOpportunities: {
    authorizationLevels: ['basic', 'admin', 'superadmin', 'megaadmin'],
    authorizations: ['securitize-markets'],
  },
  brokerDealerSystemConfig: {
    authorizationLevels: ['basic', 'admin', 'superadmin', 'megaadmin'],
    authorizations: ['securitize-markets'],
  },
  brokerDealerAccreditations: {
    authorizationLevels: ['basic', 'admin', 'superadmin', 'megaadmin'],
    authorizations: ['accreditation-lawyer'],
  },
  auditLog: {
    authorizationLevels: ['admin', 'superadmin', 'megaadmin'],
    authorizations: ['audit-log'],
  },
  'fundraise-mfe': {
    authorizationLevels: ['brokerdealer', 'admin', 'superadmin', 'megaadmin'],
    authorizations: [],
  },
};
export const checkRouteAvailability = (routeName, operatorAuthorizationLevel, operatorAuthorizations, issuerPermissions, permissionsPerIssuer) => {
  const { authorizationLevels = [], authorizations = [], brokerDealerGroupIssuerPermissions = [], hidePerIssuerPermissions = false } = routesMap[routeName] || {};
  if (authorizationLevels.length && !authorizationLevels.includes(operatorAuthorizationLevel)) return false;
  if (authorizations.length && !authorizations.some(authorization => operatorAuthorizations.includes(authorization))) return false;
  if (hidePerIssuerPermissions && permissionsPerIssuer && permissionsPerIssuer.isBDRestrictedAccess) return false;
  return !(operatorAuthorizationLevel === 'brokerdealer' && !brokerDealerGroupIssuerPermissions.every(permission => issuerPermissions[permission]));
};

export const redirectFromUnavailableRoute = (to, next) => {
  const { idIssuer } = to.params;
  if (idIssuer) {
    return next({ name: 'dashboard', params: to.params });
  }
  return next({ name: 'issuerList' });
};

export const ISR_MFE_ROUTES = [
  'investor-details-mfe',
  'assets',
  'transferControlBook',
  'transferMSF',
  'transferDTL', // Securities Transaction
  'transferProcedure',
  'affiliateManagement',
  'brokerDealerOverview', // Market Overview
  'fundraiseContent',
  'snapshots',
];
