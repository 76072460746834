export default [
  { icon: 'ion ion-ios-business', route: 'issuerList' },
  { icon: 'ion ion-ios-podium', route: 'dashboard' },
  { icon: 'ion ion-ios-people', route: 'fundraise' },
  ...process.env.VUE_APP_FUNDRAISE_MFE_ENABLED === 'true' ? [{ icon: 'ion ion-ios-people', route: 'fundraiseMfe' }] : [],
  { icon: 'ion ion-ios-people', route: 'holders' },
  { icon: 'ion ion-md-done-all', route: 'signatures' },
  { icon: 'ion ion-md-apps', route: 'issueDetails' },
  { icon: 'ion ion-ios-mail', route: 'outreach' },
  { icon: 'ion ion-ios-git-network', route: 'distributions' },
  { icon: 'ion ion-ios-git-network', route: 'snapshots' },
  {
    icon: 'ion ion-ios-cog',
    route: 'configuration',
    submenu: [
      { route: 'fundraiseConfiguration' },
      { route: 'tokenConfiguration' },
      { route: 'assetContent' },
      { route: 'jurisdictionsPerTokenConfiguration' },
    ],
  },
  {
    icon: 'fas fa-user-tie',
    route: 'transfer-agent',
    submenu: [
      { route: 'transferControlBook' },
      { route: 'transferMSF' },
      { route: 'transferDTL' },
      { route: 'transferProcedure' },
      { route: 'affiliateManagement' },
    ],
  },
  { icon: 'ion ion-ios-people', route: 'onboarding' },
  {
    icon: 'ion ion-ios-cog',
    route: 'issuer-configuration',
    submenu: [
      { route: 'generalConfiguration' },
      { route: 'jurisdictionsConfiguration' },
      { route: 'fundraiseContent' },
      { route: 'opportunitiesConfiguration' },
    ],
  },
  {
    icon: 'ion ion-ios-cog',
    route: 'advanced',
    submenu: [
      { route: 'issuerAffiliates' },
      { route: 'issuerAffiliateCampaigns' },
      { route: 'issuerAgreements' },
      { route: 'issuerBank' },
      { route: 'issuerConfiguration' },
      { route: 'issuerCurrencies' },
      { route: 'issuerEmailTemplates' },
      { route: 'issuerPlatformDocuments' },
      { route: 'issuerQuestions' },
      { route: 'issuerTexts' },
      { route: 'issuerPrerenderedAddresses' },
    ],
  },
  { icon: 'ion ion-ios-eye-off', route: 'privacyControl' },
  { icon: 'ion ion-ios-book', route: 'auditLogIssuer' },
  {
    icon: 'ion ion-md-finger-print',
    route: 'securitize-id',
    submenu: [
      { route: 'securitizeId', exact: true },
      ...(process.env.VUE_APP_SID_MFE_INVESTORS_LEGACY === 'true' ? [{ route: 'securitizeIdMfe' }] : []),
      { route: 'securitizeIdTranslations' },
      ...(process.env.VUE_APP_SID_MFE_TRANSLATIONS_LEGACY === 'true' ? [{ route: 'securitizeIdTranslationsMfe' }] : []),
      { route: 'securitizeIdWallets' },
      ...(process.env.VUE_APP_SID_MFE_WALLETS_LEGACY === 'true' ? [{ route: 'securitizeIdWalletsMfe' }] : []),
      ...(process.env.VUE_APP_SID_MFE_PROMOTIONS === 'true' ? [{ route: 'securitizeIdPromotionsMfe' }] : []),
    ],
  },
  { icon: 'ion ion-ios-build', route: 'operators' },
  {
    icon: 'ion ion-ios-cog',
    route: 'system-config',
    submenu: [
      { route: 'systemAgreements' },
      { route: 'systemQuestions' },
      { route: 'systemCountries' },
      { route: 'systemEmailTemplates' },
      { route: 'systemTexts' },
      { route: 'clientConfiguration' },
    ],
  },
  {
    icon: 'ion ion-ios-briefcase',
    route: 'broker-dealer',
    submenu: [
      { route: 'brokerDealerOverview' },
      { route: 'brokerDealerInvestors' },
      { route: 'brokerDealerOpportunities' },
      { route: 'brokerDealerSystemConfig' },
      { route: 'brokerDealerAccreditations' },
    ],
  },
  { icon: 'ion ion-ios-book', route: 'auditLog' },
];
